.team-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: white;
    padding-bottom: 100px;
    height: 100%;
    min-height: 100vh;
    font-size: 18px;
    padding-top: 100px;
    margin-right: 10%;
    margin-left: 10%;
}

.vision {
    font-size: 12px;
    text-align: left;
    font: "Martel Sans", sans-serif;
    margin-bottom: 40px;
    color: rgb(214, 214, 214);
}

.item {
    font-size: 12px;
}

.vision-statement {
    font-size: 16px;
}

.list-vision {
    margin-left: 40px;
    margin-bottom: 60px;
}

.team-section h2 {
    font-family: "Martel Sans", sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    font-size: 30px;
}

.team-section p {
    font-family: "Martel Sans", sans-serif;
}

.member-info {
    width: 300px;
    font-size: 12px;
}

.team-leads, .team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
    margin-bottom: 20px;
}

.team-leads:hover, .team-member:hover {
    cursor: pointer;
}

.team-leads {
    margin-bottom: 100px;
}

.team-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0%;
    color: white;
}

.team-member img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.868);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    margin-top: 100px;
    width: 80%;
    max-width: 1500px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.367);
    border-radius: 10px;
    display: flex;
    padding: 50px;
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
}

.test {
    margin-left: 50px;
    margin-top: 30px;
}

.name {
    font-size: 1.3rem;
    font-weight: 400;
    text-align: left;
    margin-top: 40px;
}

.info {
    margin-top: 40px;
    font-size: 0.45rem;
    text-align: left;
    margin-right: 50px;
    max-height: 80%;
}

.modal-content img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    object-fit: cover;
    margin-top: 40px;
    margin-left: 20px;
}

.navi {
    position: absolute;
    display: flex;
    margin-bottom: 50px;
    bottom: 100px;
}

.next, .prev {
    font-size: 30px;
    cursor: pointer;
    color: white;
}


.close {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 50px;
    z-index: 1050;
}

.next:hover, .prev:hover, .close:hover {
    color: #ffa500;;
}

@media screen and (max-width: 768px) {
    .modal-content {
        flex-direction: column;
        align-items: center;
        padding-bottom: 70px;
        height: 100%;
        width: 100%;
    }

    .modal-content img {
        width: 200px;
        height: 200px;
        margin-top: 20px;
    }

    .test {
        width: 100%;
        margin-left: 0;
    }

    .info {
        margin-right: 0;
    }

    .close {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .navi {
        display: flex;
        position: static;
        width: 100%;
        margin-top: 20px;
        margin-bottom: 0px;
    }

    .next, .prev {
        position: relative;
        bottom: 0px;
        font-size: 24px;
        margin: 0px;
    }
}
