/* Import the Inter font */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700&display=swap');

.carousel {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 20px; /* Reduce vertical padding to shrink height */
    z-index: 1100;
    font-family: 'Inter', sans-serif; /* Updated to use Inter font */
    position: absolute;
    bottom: clamp(50px, 5vw, 100px); /* Dynamically adjust bottom spacing */
    left: 0;
    width: 100%; /* Ensure it spans the full width */
    background: rgba(217, 217, 217, 1); /* Light gray background for carousel */
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.8); /* Add gradient-like effect inside the box */
    border: none; /* Remove the hard border */
    box-sizing: border-box; /* Ensure padding and border are included in width/height */
}

.carousel-inner {
    display: flex;
    animation: slide linear infinite;
    color: rgba(0, 0, 0, 1); /* Text color set to black */
    font-family: 'Inter', sans-serif; /* Use Inter font */
    font-size: 12.5px; /* Exact font size */
    font-weight: 100; /* Thin weight as per design */
    line-height: 18px; /* Exact line height */
    text-align: center; /* Center-align text */
    text-underline-position: from-font; /* Use underline based on font metrics */
    text-decoration-skip-ink: none; /* Underline goes through descenders */
}

.carousel-item {
    flex: none;
    width: clamp(250px, 50vw, 400px); /* Wider items for better readability */
    margin-right: clamp(30px, 5vw, 120px); /* Add spacing dynamically */
    padding: 10px; /* Add internal padding for better readability */
    white-space: normal; /* Allow text wrapping */
    text-align: center; /* Center-align each carousel item's text */
    font-weight: 550; /* Make the text bold */
    /* background: transparent; Keep the item background transparent */
}
.carousel-item:hover {
    transform: scale(1.05); /* Slight zoom */
    transition: transform 0.3s ease;
}

/* Keyframes for sliding animation */
@keyframes slide {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% / 2));
    }
}

/* Responsive Design */

/* Medium Screens */
@media (max-width: 800px) {
    .carousel {
        bottom: 80px; /* Adjust bottom position for medium screens */
        padding: 15px; /* Reduce padding */
    }
    .carousel-item {
        width: clamp(300px, 60vw, 350px); /* Wider items for medium screens */
        margin-right: clamp(40px, 4vw, 80px); /* Adjust spacing dynamically */
        font-size: clamp(14px, 2vw, 16px); /* Adjust font size dynamically */
    }
}

/* Small Screens */
@media (max-width: 600px) {
    .carousel {
        bottom: 70px; /* Adjust bottom position further */
        padding: 10px;
    }
    .carousel-item {
        width: clamp(280px, 70vw, 320px); /* Wider items for small screens */
        margin-right: clamp(30px, 3vw, 60px); /* Adjust spacing dynamically */
        font-size: clamp(13px, 1.8vw, 15px); /* Adjust font size */
    }
}

/* Extra Small Screens */
@media (max-width: 400px) {
    .carousel {
        padding: 12px;
    }
    .carousel-item {
        margin-right: clamp(10px, 3vw, 20px);
    }
}

