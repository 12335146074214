.products-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: black;
    margin-top: 50px;
}

/* Services Heading */
.services-heading {
    font-family: 'Inter', sans-serif;
    color: rgba(255, 255, 255, 1);
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 50px;
    position: relative;
}

.services-heading::after {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 2px;
}

/* Vertical Line Between Products */
.vertical-line {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    margin: 0 20px;
    display: block;
}

/* Product Container */
.product-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    gap: 20px;
}

/* Product Item */
.product-item {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: flex-start;
    flex: 1 1 calc(33.333% - 40px);
    max-width: 320px;
    height: auto;
    background-color: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.8);
}

/* Product Image */
.product-image {
    width: 100%;
    max-width: 320px;
    height: 240px;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.6);
}

/* Product Title */
.product-title {
    text-align: center;
    font-family: "Martel Sans", sans-serif;
    color: white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
}

/* Product Text */
.product-text {
    text-align: center;
    font-family: "Martel Sans", sans-serif;
    color: white;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

/* Get In Touch Button */
.get-in-touch-button {
    margin-top: 50px;
    padding: 12px 25px;
    background: linear-gradient(45deg, #ff416c, #ff4b2b);
    color: white;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
}

.get-in-touch-button:hover {
    background: linear-gradient(45deg, #ff4b2b, #ff416c);
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(255, 75, 43, 0.6);
}

/* View Dataset Button */
.view-dataset-button {
    margin-top: 10px;
    padding: 10px 25px;
    background: linear-gradient(45deg, #6a11cb, #2575fc);
    color: white;
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    border-radius: 50px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.view-dataset-button:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(39, 92, 242, 0.6);
}

/* Category Dropdown */
.category-dropdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
}

.category-button {
    padding: 10px 20px;
    background: rgba(255, 255, 255, 0.2);
    color: white;
    border: none;
    border-radius: 50px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.category-button.active {
    background: linear-gradient(45deg, #6a11cb, #2575fc); /* Highlight active button */
    color: white;
    box-shadow: 0 4px 10px rgba(39, 92, 242, 0.6);
}

.category-button:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb);
    transform: translateY(-2px);
}

/* Dataset Table */
.dataset-table {
    width: 100%; /* Full width of the modal */
    max-width: 600px; /* Restrict the table's width */
    margin: 0 auto; /* Center the table */
    border-collapse: collapse; /* Remove spacing between cells */
    background: rgba(255, 255, 255, 0.05); /* Subtle background color */
    border-radius: 12px;
    overflow: hidden; /* Ensure the rounded corners apply */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Subtle shadow */
    font-family: 'Inter', sans-serif;
    font-size: 14px; /* Reduced text size */
}

.dataset-table th,
.dataset-table td {
    padding: 8px 12px; /* Reduce padding */
    text-align: left; /* Align text to the left */
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); /* Subtle row separator */
}

.dataset-table th {
    background: rgba(39, 92, 242, 0.2); /* Header row background */
    font-weight: 600; /* Bold headers */
    text-transform: uppercase; /* Uppercase text for headers */
}

.dataset-table td {
    background: transparent; /* Transparent background for table rows */
    color: #d0d0d0; /* Slightly lighter text for body rows */
}

.dataset-table tr:last-child td {
    border-bottom: none; /* Remove the bottom border for the last row */
}

/* Urdu Alignment in Table */
.dataset-table td:nth-child(4) {
    text-align: right;
    direction: rtl;
    font-family: "Noto Nastaliq Urdu", serif; /* Use an Urdu font */
    color: #f2f2f2; /* Adjust Urdu text color for better readability */
}
/* Modal Styling */
.dataset-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dataset-content {
    background: #1e1e1e;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.6);
    text-align: center;
    width: 90%;
    max-width: 700px;
}

/* Audio Data Section */
.audio-data-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px 0;
}

.audio-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    transition: all 0.3s ease;
}

.audio-item:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4);
}

.audio-label {
    color: white;
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    width: 100%;
}

.audio-player {
    width: 100%;
    border-radius: 8px;
}

.audio-transcription {
    color: #d0d0d0;
    font-size: 14px;
    line-height: 1.8;
    text-align: justify;
    background: rgba(255, 255, 255, 0.05);
    padding: 10px;
    border-radius: 8px;
    direction: rtl;
    font-family: "Noto Nastaliq Urdu", serif;
}

/* Close Modal Button */
.close-modal-button {
    margin-top: 20px;
    padding: 12px 30px;
    background: linear-gradient(45deg, #ff416c, #ff4b2b);
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.close-modal-button:hover {
    background: linear-gradient(45deg, #ff4b2b, #ff416c);
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(255, 75, 43, 0.6);
}

/* Responsive Design */

/* Medium Screens (Tablet) */
@media (max-width: 1200px) {
    .product-container {
        flex-wrap: wrap;
        justify-content: center;
    }

    .product-item {
        flex: 1 1 calc(45% - 20px);
        max-width: 400px;
    }
}

/* Small Screens (Mobile) */
@media (max-width: 768px) {
    .product-container {
        flex-direction: column;
        align-items: center;
    }

    .product-item {
        flex: 1 1 100%;
        max-width: 320px;
    }

    .product-image {
        max-width: 280px;
    }

    .audio-item {
        max-width: 100%;
    }
}

/* Extra Small Screens */
@media (max-width: 480px) {
    .product-image {
        max-width: 250px;
    }

    .audio-transcription {
        font-size: 12px;
    }
}

/* Urdu Alignment in Table */
.dataset-table td:nth-child(4) {
    text-align: right;
    direction: rtl;
    font-family: "Noto Nastaliq Urdu", serif; /* Use an Urdu font */
    color: #f2f2f2; /* Adjust Urdu text color for better readability */
}

/* Responsive Design for Dataset Table */
@media (max-width: 768px) {
    .dataset-table {
        font-size: 12px; /* Further reduce text size on smaller screens */
    }

    .dataset-table th,
    .dataset-table td {
        padding: 6px 10px; /* Reduce padding for compact layout */
    }
}

@media (max-width: 480px) {
    .dataset-table {
        font-size: 11px; /* Make the font smaller for very small screens */
    }

    .dataset-table th,
    .dataset-table td {
        padding: 5px 8px; /* Compact padding */
    }
}