@import url('https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700&display=swap');

:root {
    --minFontSize: 2vw; /* Minimum font size */
    --maxFontSize: 18px; /* Maximum font size */
    --scaler: 4vw; /* Adjust based on your design requirements */
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
}

*, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-container {
    width: 100%; 
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    /* padding-bottom: 100px;` */
}

.section {
    width: 80%; /* Adjusted width for better text fit */
    max-width: 1200px;
    padding: 1em 2.25em;
    border-left: 5px solid #ffa500;
    /* text-align: center; */
    color: white;
    font-family: "Martel Sans", sans-serif;
    font-weight: bold;
    overflow-wrap: break-word;
    /* word-break: keep-all;  */
}

.section span {
    display: inline-block;
    transform: scaleY(0);
    transform-origin: bottom;
    white-space: pre-wrap;
    animation-fill-mode: forwards;
}

.more-about-us {
    display: flex;
    width:100px;
    padding: 4px;
    font-size: 12px;
    font-weight: 300;
    border-radius: 5px;
    margin-top: 20px;
    color: white;
    border: 2px solid white; 
    justify-content: center;
    align-items: center;
}

.more-about-us:hover {
    cursor: pointer;
    border: 2px solid #ffa500;
    color: #ffa500;
}

@keyframes scale {
    0% { transform: scaleY(0); }
    100% { transform: scaleY(1); }
}

.contact-us {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: rgb(0, 0, 0);
    color: rgb(135, 135, 135);
    font-size: 16px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-top: 20px;
} 

.social-media-icons {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    /* margin-right: 100px; */
    /* background-color: green; */
}

.contact-info div {
    /* margin: 20px; */
}

.p-policy{
    margin-right: 100px;
    cursor: pointer;
    text-decoration: underline;
}

.contact-info a {
    text-decoration: none;
    color: rgb(135, 135, 135);
}


.social-media-icons a {
    margin-right: 10px;
}

.social-media-icons img {
    width: 20px;
    height: 20px;
}

@media (max-width: 1000px) {
    .contact-us {
        flex-direction: row;
        margin-top: 20px;
        margin-left: 0px;
        font-size: 12px;
    }
    .social-media-icons {
        margin: 0px;
        margin-top: 2px;
    }

    .contact-info {
        margin: 0px;
        margin-left: 20px;
    }

    .p-policy {
        margin-right: 20px;
    }
    .social-media-icons img {
        width: 15px;
        height: 15px;
    }
}

.h-line {
    border-bottom: 3px solid rgb(135, 135, 135);;
    width: 90%;
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 100px;
  }