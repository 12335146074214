@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Martel+Sans:wght@200;300;400;600;700;800;900&display=swap');

.App {
  background-color: black;
  
}

.dotted-line1 {
  border-bottom: 1px solid #a1a1a1;
  width: 100%;
}

.carosel {
  z-index: 500;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 500;
}

.home-and-carosel-wrapper {
  position: relative; /* Create a positioning context */
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background-color: #000000;
}

::-webkit-scrollbar-thumb {
  background-color: #ffa500;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #966200;
}

.p-policy a {
  color: inherit; /* This will make the link inherit the text color from its parent */
  text-decoration: none; /* Remove underline */
}

.p-policy a:hover {
  text-decoration: underline; /* Optional: Add underline on hover */
}
