@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* General Layout */
.home-section {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    opacity: 0.5;
    z-index: 1;
    max-width: 100%;
    max-height: 100%;
}

.overlay-gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0.8) 90%);
    z-index: 2;
    pointer-events: none;
}

/* Content Container */
.content-container {
    position: relative;
    top: -15%;
    z-index: 3;
    color: white;
    text-align: center;
    padding: clamp(10px, 5vw, 20px); /* Dynamically adjust padding */
    max-width: 1200px;
    margin: auto;
}

/* Hero Text */
.hero-text {
    margin-bottom: 50px;
}

.main-heading {
    font-family: 'Poppins', sans-serif;
    font-size: clamp(28px, 5vw, 64px); /* Dynamically scale between 28px and 64px */
    font-weight: 700;
    line-height: 1.2;
    color: white;
    margin-bottom: 20px;
}

.main-heading .highlight {
    color: #ffa500;
    background: linear-gradient(to right, #ff8c00, #ffa500);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subheading {
    font-family: 'Poppins', sans-serif;
    font-size: clamp(16px, 2vw, 22px); /* Dynamically scale for responsiveness */
    font-style: italic; /* Add italics */
    font-weight: 300; /* Lighter font weight for a modern look */
    color: #ffffff; /* Bright white for readability */
    margin-top: 15px; /* Add more space between main heading and subheading */
    line-height: 1.6; /* Adjust line height for better readability */
    text-align: center; /* Center-align the subheading */
    letter-spacing: 0.5px; /* Slightly increased letter spacing for a cleaner appearance */
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.6); /* Add a subtle shadow for depth */
    background: linear-gradient(to right, #ff8c00, #ffa500); /* Gradient background for the text */
    -webkit-background-clip: text; /* Apply gradient to the text */
    -webkit-text-fill-color: transparent; /* Make background visible through text */
}

/* What's New Section */
.whats-new-section {
    position: absolute;
    bottom: clamp(100px, 10%, 300px); /* Dynamically adjust bottom margin */
    left: clamp(10px, 5vw, 20px); /* Dynamically adjust left margin */
    background-color: rgba(0, 0, 0, 0); /* Transparent background */
    color: #ffffff;
    padding: 15px 20px;
    border-radius: 8px;
    text-align: left;
    max-width: 90%; /* Ensure it doesn't overflow horizontally */
    z-index: 10; /* Keep above other content */
    box-sizing: border-box;
}

.whats-new-title {
    font-size: clamp(16px, 2.5vw, 18px); /* Dynamically scale between 16px and 18px */
    font-weight: bold;
    color: #f3c700; /* Yellow color for "What's New" */
    margin: 0 0 10px;
    text-decoration: underline;
}

.whats-new-description {
    font-size: clamp(14px, 2vw, 16px); /* Dynamically scale between 14px and 16px */
    margin: 0 0 15px;
    white-space: normal; /* Allow text wrapping */
    word-wrap: break-word; /* Ensure long words break */
}

.visit-gradassist-button {
    background-color: transparent;
    color: #ffffff;
    border: 2px solid rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 8px 12px;
    font-size: clamp(12px, 1.5vw, 14px); /* Dynamically scale button text */
    border-radius: 50px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s, color 0.3s;
}

.visit-gradassist-button:hover {
    background-color: #ffffff;
    color: #000000;
}

/* Responsive Design */

/* Large Screens (Desktop) */
@media (min-width: 1200px) {
    .main-heading {
        font-size: 64px;
    }
    .sub-heading {
        font-size: 20px;
    }
    .whats-new-section {
        bottom: 250px;
        left: 50px;
    }
}

/* Medium Screens (Tablets) */
@media (max-width: 1200px) {
    .main-heading {
        font-size: 48px;
    }
    .sub-heading {
        font-size: 18px;
    }
    .whats-new-section {
        bottom: 220px;
        left: 40px;
        padding: 12px 16px;
    }
    .visit-gradassist-button {
        font-size: 13px;
        padding: 8px 15px;
    }
}

/* Small Screens (Mobile Landscape) */
@media (max-width: 768px) {
    .main-heading {
        font-size: 36px;
    }
    .sub-heading {
        font-size: 16px;
    }
    .content-container {
        padding: 10px;
    }
    .whats-new-section {
        bottom: 160px;
        left: 20px;
    }
    .visit-gradassist-button {
        font-size: 12px;
        padding: 6px 12px;
    }
}

/* Extra Small Screens (Mobile Portrait) */
@media (max-width: 480px) {
    .main-heading {
        font-size: 28px;
        line-height: 1.2;
    }
    .sub-heading {
        font-size: 14px;
    }
    .whats-new-section {
        bottom: 280px;
        left: 10px;
        max-width: 100%;
        padding: 10px 12px;
    }
    .whats-new-title {
        font-size: 14px;
    }
    .whats-new-description {
        font-size: 12px;
    }
    .visit-gradassist-button {
        font-size: 10px;
        padding: 5px 10px;
    }
}
