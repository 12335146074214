.contact-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    color: white;
    padding-bottom: 100px;
    height: 100vh;
    font-size: 18px;
    padding-top: 100px;
    margin-right: 10%;
    margin-left: 10%;
    
}

.social-media-contacts {
    display: flex;
    align-items: center;
    justify-content: center;
}

.email {
    font-size: 24px;
    margin-bottom: 100px;
}

.insta{
    height: 40px;
    width: auto;
}

.fb{
    height: 40px;
    width: auto;
}

.ln{
    height: 40px;
    width: auto;
}

.yt{
    height: 40px;
    width: auto;
}

.x{
    height: 40px;
    width: auto;
}

.social-media-contacts a{
    margin-right: 20px;
}
