@import url('https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap');
body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Prevent horizontal overflow */
    overflow-y: auto; /* Ensure vertical scrolling behaves correctly */
}


.navbar-section {
    display: flex;
    z-index: 100;
    width: 100vw;
    height: 60px;
    top: 0;
    font-family: "Tinos", serif;
    letter-spacing: 0.05rem;
    background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black */
    border: none; /* Ensure no border */

}

.nav-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.menu-icon-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    margin-right: 50px;
    color: rgb(255, 255, 255);
    transition: color 0.5s ease;
}

.menu-icon-container:hover {
    cursor: pointer;
    color: #ffa500;
    transition: color 0.5s ease;
}

.logo-container {
    display: flex;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: 500;
    margin-left: 50px;
}

.logo-container:hover {
    cursor: pointer;
}

.logo {
    height: 40px;
}

.logo-text {
    margin-left: 10px;
    margin-top: 5px;
}

.logo-text-orange {
    color: #ffa500;
}

.navbar-items {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.802);
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
    transition: color 0.5s ease;
}

.nav-item-expanded {
    margin-top: 20px;
}

.navbar-expanded {
    display: flex;
}

.nav-item-expanded:hover {
    cursor: pointer;
    color: #ffa500;
    transition: color 0.5s ease;
}

.close-icon {
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 50px;
    margin-top: 50px;
    transition: color 0.5s ease;
}

.close-icon:hover {
    cursor: pointer;
    color: #ffa500;
    transition: color 0.5s ease;
}

@media (max-width: 800px) {
    .menu-icon-container {
        margin-right: 20px;
    }
    .logo-container {
        margin-left: 20px;
    }
}

