/* Container for the privacy policy */
.privacy-container {
    display: flex;
    flex-direction: column;
    background-color: black;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.p-con {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 80%;
    margin-top: 80px;
    padding: 4%;
    padding-top: 20px;
    background-color: #0f0f0f;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    overflow-y: auto;
    font-family: "Martel Sans", sans-serif;
    color: rgb(203, 203, 203);
    font-size: 0.4rem;
}

#privacy-main-heading {
    color: white;
    text-align: center;
    font-size: 1.3rem;
    margin-bottom: 20px;
}

.privacy-section-heading {
    color: white;
    margin-top: 20px;
    font-size: 0.7rem;
}

.privacy-subsection-heading {
    color: white;
    margin-top: 10px;
    font-size: 0.5rem;
}

/* List styles */
li {
    margin-bottom: 10px;
    font-size: 0.4rem;
}

/* Links */
a {
    color: #87CEEB; /* Sky blue color for links */
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
